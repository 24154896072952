.fu-segment {
  display: flex;
  position: fixed;
  z-index: 999;
  padding: var(--fu-spacing-m) var(--fu-spacing-s);
  gap: var(--fu-spacing-xs);
  left: 0;
  right: 0;
  background: white;

  &--auto-width {
    ion-segment-button {
      width: auto !important;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ion-segment-button {
    text-transform: capitalize;
    letter-spacing: normal;
    font-size: var(--fu-font-size-xxs);
    font-weight: var(--fu-font-medium);
    min-height: 44px;
    width: 100%;
    border-radius: var(--fu-radius-input);
    --padding-end: var(--fu-spacing-xl);
    --padding-start: var(--fu-spacing-xl);
    --color: var(--fu-mild-grey);
    --border-width: 1px;
    --border-color: var(--fu-grey-16);
    --border-style: solid;
    --color-checked: var(--fu-body-bg);
    --background-checked: var(--fu-body-color);
    --ripple-color: transparent;

    &::part(indicator) {
      display: none;
    }

    ion-icon {
      font-size: var(--fu-font-size-xl);
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  &--bar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--fu-spacing-4xl);
    overflow: hidden;
    padding: var(--fu-spacing-m) var(--fu-spacing-s) calc(var(--fu-spacing-m) + var(--ion-safe-area-bottom)) var(--fu-spacing-s);
    min-height: 100px;
    border-top: 1px solid var(--fu-grey-15);
    background: var(--fu-body-bg);
    backdrop-filter: blur(2.5px);

    ion-segment-button {
      min-height: 64px;
      width: fit-content;
      --border-width: 0;
      --border-color: none;
      --color-checked: var(--fu-body-color);
      --color: var(--fu-grey-3);
      --background-checked: transparent;
      --padding-end: 0;
      --padding-start: 0;
      backdrop-filter: blur(2.5px);

      ion-icon {
        font-size: var(--fu-icon-font-size-l);
      }
    }
  }
}

.fu-segment-tab {
  display: block;
  margin-top: 66px;

  &--with-bg {
    margin-top: 86px;
  }
}
