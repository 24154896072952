@use 'libs/core/src/lib/theme/helper' as helper;

.fu-card-header {
  margin: 0 var(--fu-content-padding-side);
  margin-bottom: var(--fu-spacing-l);
}

hr + .fu-card-header {
  margin-top: var(--fu-spacing-xxl);
}

.fu-card-footer {
  @include helper.flex-space-between();
  margin: 0 var(--fu-spacing-s);
  margin-top: var(--fu-spacing-l);

  div {
    @include helper.flex-align-center();
    gap: var(--fu-spacing-xxs);
  }

  &--icon {
    font-size: 20px;
  }
}

.fu-card-container {
  @include helper.flex-column();
  gap: var(--fu-spacing-l);
}

.fu-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--fu-spacing-s);
}

@media screen and (min-width: 768px) {
  .fu-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .fu-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  .fu-cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

