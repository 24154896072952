.fu-theme {
  color: var(--fu-body-color);
  background-color: var(--fu-body-bg);

  --background: var(--fu-body-bg);
  --ion-text-color: var(--fu-body-color);
  --ion-background-color: var(--fu-body-bg);
  --ion-color-primary: var(--fu-button-primary-bg);
  --ion-color-primary-shade: var(--fu-button-primary-bg);
  --ion-color-primary-tint: var(--fu-button-primary-bg);
  --ion-color-primary-contrast: var(--fu-button-primary-color);
  --ion-color-secondary: var(--fu-button-secondary-bg);
  --ion-color-secondary-contrast: var(--fu-button-secondary-color);
  --ion-color-tertiary: var(--fu-button-tertiary-bg);
  --ion-color-tertiary-contrast: var(--fu-button-tertiary-color);
  --ion-color-warning: var(--fu-color-warning);
  --ion-color-success: var(--fu-color-success);
  --ion-color-danger: var(--fu-color-error);
  --fu-header-bg: var(--fu-header-bg-light);
  --fu-toggle-track-bg: var(--fu-toggle-track-bg-light);
  --fu-progress-bar-bg: var(--fu-progress-bar-bg-light) ;

  --fu-icon-primary-color: var(--fu-color-primary);

  &--dark, &--dark-settings, &--dark-activity, &--dark-nutrition, &--dark-challenge, &--dark-subscription, &--dark-membership, &--dark-moveathlon, &--dark-zpp {
    --fu-body-bg: var(--fu-body-bg-dark);
    --fu-body-bg-rgb: var(--fu-black-rgb);
    --fu-body-color: var(--fu-body-color-dark);
    --fu-body-color-rbg: var(--fu-pure-white-rgb);
    --fu-border-color: var(--fu-hr-color-dark);

    --fu-button-primary-bg: var(--fu-button-primary-bg-dark);
    --fu-button-primary-color: var(--fu-button-primary-color-dark);

    --fu-button-secondary-bg: var(--fu-button-secondary-bg-dark);
    --fu-button-secondary-color: var(--fu-button-secondary-color-dark);

    --ion-color-tertiary: var(--fu-button-tertiary-bg-dark);
    --ion-color-tertiary-contrast: var(--fu-button-tertiary-color-dark);

    --fu-button-ic-only-bg: var(--fu-button-ic-only-bg-dark);
    --fu-button-ic-only-color: var(--fu-button-ic-only-color-dark);

    --fu-segment-bg: var(--fu-segment-bg-dark);
    --fu-segment-bg-active: var(--fu-segment-bg-active-dark);

    --fu-table-bg: var(--fu-table-bg-dark);
    --fu-table-color: var(--fu-table-color-dark);
    --fu-table-divider-color: var(--fu-table-divider-color-dark);

    --fu-banner-bg: var(--fu-banner-bg-dark);
    --fu-banner-color: var(--fu-banner-color-dark);

    --fu-cta-button-bg: var(--fu-cta-button-bg-dark);

    --fu-input-bg: var(--fu-input-bg-dark);
    --fu-input-color: var(--fu-input-color-dark);
    --fu-input-placeholder-color: var(--fu-input-placeholder-color-dark);
    --fu-input-border: var(--fu-input-border-dark);
    --fu-input-border-style: var(--fu-input-border-style-dark);
    --fu-input-border-width: var(--fu-input-border-width-dark);
    --fu-input-border-color: var(--fu-input-border-color-dark);

    --fu-footer-info-border: var(--fu-footer-info-border-dark);

    --fu-number-button-color: var(--fu-number-button-color-dark);
    --fu-number-button-bg: var(--fu-number-button-bg-dark);

    --fu-header-bg: var(--fu-header-bg-dark);

    --fu-editor-disabled-button: var(--fu-editor-disabled-button-bg-dark);

    --fu-toggle-track-bg: var(--fu-toggle-track-bg-dark);

    --fu-button-border-color: var(--fu-button-border-color-dark);

    --fu-radio-box-border-color: var(--fu-radio-box-border-color-dark);
    --fu-radio-box-selected-bg: var(--fu-radio-box-selected-bg-dark);
    --fu-radio-box-selected-color: var(--fu-radio-box-selected-color-dark);

    --fu-wheel-color: var(--fu-wheel-color-dark);
    --fu-wheel-highlight-bg: var(--fu-wheel-highlight-bg-dark);

    --fu-square-input-border-color: var(--fu-square-input-border-color-dark);
    --fu-square-input-bg: var(--fu-square-input-bg-dark);
    --fu-square-control-bg: var(--fu-square-control-bg-dark);

    --fu-circle-bg-color: var(--fu-circle-bg-color-dark);

    --fu-challenge-card-bg: var(--fu-challenge-card-bg-dark);
    --fu-challenge-card-border: var(--fu-challenge-card-border-dark);
    --fu-progress-bar-bg: var(--fu-progress-bar-bg-dark) ;
 }

  &--light, &--light-settings, &--light-activity, &--light-nutrition, &--light-challenge, &--light-subscription, &--light-guide {
    --fu-body-bg: var(--fu-body-bg-light);
    --fu-body-bg-rgb: var(--fu-pure-white-rgb);
    --fu-body-color: var(--fu-body-color-light);
    --fu-border-color: var(--fu-hr-color-light);

    --fu-button-secondary-bg: var(--fu-button-secondary-bg-light-settings);
    --fu-button-secondary-color: var(--fu-button-secondary-color-light-settings);

    --ion-color-tertiary: var(--fu-button-tertiary-bg-light);
    --ion-color-tertiary-contrast: var(--fu-button-tertiary-color-light);

    --fu-input-bg: var(--fu-input-bg-light);
    --fu-input-color: var(--fu-input-color-light);
    --fu-input-placeholder-color: var(--fu-input-placeholder-color-light);
    --fu-input-border-style: var(--fu-input-border-style-light);
    --fu-input-border-width: var(--fu-input-border-width-light);
    --fu-input-border-color: var(--fu-input-border-color-light);

    --fu-button-ic-only-bg: var(--fu-button-ic-only-bg-light);
    --fu-button-ic-only-color: var(--fu-button-ic-only-color-light);

    --fu-segment-bg: var(--fu-segment-bg-light);
    --fu-segment-bg-active: var(--fu-segment-bg-active-light);

    --fu-table-bg: var(--fu-table-header-bg-light);
    --fu-table-color: var(--fu-table-color-light);
    --fu-table-divider-color: var(--fu-table-divider-color-light);

    --fu-banner-bg: var(--fu-banner-bg-light);
    --fu-banner-color: var(--fu-banner-color-light);

    --fu-cta-button-bg: var(--fu-cta-button-bg-light);

    --fu-footer-info-border: var(--fu-footer-info-border-light);

    --fu-number-button-color: var(--fu-number-button-color-light);
    --fu-number-button-bg: var(--fu-number-button-bg-light);

    --fu-header-bg: var(--fu-header-bg-light);

    --fu-editor-disabled-button: var(--fu-editor-disabled-button-bg-light);

    --fu-toggle-track-bg: var(--fu-toggle-track-bg-light);

    --fu-button-border-color: var(--fu-button-border-color-light);

    --fu-radio-box-border-color: var(--fu-radio-box-border-color-light);
    --fu-radio-box-selected-bg: var(--fu-radio-box-selected-bg-light);
    --fu-radio-box-selected-color: var(--fu-radio-box-selected-color-light);

    --fu-wheel-color: var(--fu-wheel-color-light);
    --fu-wheel-highlight-bg: var(--fu-wheel-highlight-bg-light);

    --fu-square-input-border-color: var(--fu-square-input-border-color-light);
    --fu-square-input-bg: var(--fu-square-input-bg-light);
    --fu-square-control-bg: var(--fu-square-control-bg-light);

    --fu-circle-bg-color: var(--fu-circle-bg-color-light);

    --fu-challenge-card-bg: var(--fu-challenge-card-bg-light);
    --fu-challenge-card-border: var(--fu-challenge-card-border-light);
    --fu-progress-bar-bg: var(--fu-progress-bar-bg-light);
  }

  &--light {
    --fu-button-primary-bg: var(--fu-button-primary-bg-light);
    --fu-button-primary-color: var(--fu-button-primary-color-light);
    --fu-button-primary-bg-rgb: var(--fu-icy-green-rgb);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
  }

  &--dark {
    --fu-button-primary-bg-rgb: var(--fu-icy-green-rgb);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
  }

  &--light-settings {
    --fu-button-primary-bg: var(--fu-button-primary-bg-light-settings);
    --fu-button-primary-bg-rgb: var(--fu-button-primary-bg-light-settings-rgb);
    --fu-button-primary-color: var(--fu-button-primary-color-light-settings);
    --fu-button-primary-color-rgb: var(--fu-button-primary-color-light-settings-rgb);
    --fu-button-tertiary-bg-light: var(--fu-grey-15);
    --fu-button-tertiary-color-light: var(--fu-body-color);
  }

  &--dark-settings {
    --fu-button-primary-bg: var(--fu-button-primary-bg-dark-settings);
    --fu-button-primary-bg-rgb: var(--fu-button-primary-bg-dark-settings-rgb);
    --fu-button-primary-color: var(--fu-button-primary-color-dark-settings);
    --fu-button-primary-color-rgb: var(--fu-button-primary-color-dark-settings-rgb);
  }

  &--light-fit-score {
    --fu-color-primary: var(--fu-color-fit-score);
    --fu-color-primary-rgb: var(--fu-color-fit-score-rgb);
    --fu-button-primary-bg: var(--fu-color-primary);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
    --fu-icon-primary-color: var(--fu-color-primary);
  }

  &--light-activity, &--dark-activity {
    --fu-color-primary: var(--fu-color-activity);
    --fu-color-primary-rgb: var(--fu-color-activity-rgb);
    --fu-icon-primary-color: var(--fu-color-activity);
    --fu-button-disabled-bg: var(--fu-grey-15);
    --fu-button-disabled-color: var(--fu-grey-5)
  }

  &--light-activity {
    --fu-button-primary-bg: var(--fu-black);
    --fu-button-primary-bg-rgb: var(--fu-black-rgb);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
  }

  &--dark-activity {
    --fu-button-primary-bg: var(--fu-color-activity);
    --fu-button-primary-bg-rgb: var(--fu-color-activity-rgb);
    --fu-button-primary-color: var(--fu-black);
    --fu-button-primary-color-rgb: var(--fu-black-rgb);
  }

  &--light-nutrition, &--dark-nutrition {
    --fu-color-primary: var(--fu-color-nutrition);
    --fu-color-primary-rgb: var(--fu-color-nutrition-rgb);
    --fu-button-primary-bg: var(--fu-color-nutrition);
    --fu-button-primary-bg-rgb: var(--fu-color-nutrition-rgb);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
    --fu-icon-primary-color: var(--fu-color-nutrition);
  }

  &--light-challenge, &--dark-challenge {
    --fu-color-primary: var(--fu-color-challenge);
    --fu-color-primary-rgb: var(--fu-color-challenge-rgb);
    --fu-button-primary-bg: var(--fu-color-challenge);
    --fu-button-primary-bg-rgb: var(--fu-color-challenge-rgb);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
    --fu-icon-primary-color: var(--fu-color-challenge);
  }

  &--light-subscription, &--dark-subscription {
    --fu-color-primary: var(--fu-color-subscription);
    --fu-color-primary-rgb: var(--fu-color-subscription-rgb);
    --fu-button-primary-bg: var(--fu-color-subscription);
    --fu-button-primary-bg-rgb: var(--fu-color-subscription-rgb);
    --fu-button-primary-color: var(--fu-black);
    --fu-button-primary-color-rgb: var(--fu-black-rgb);
    --fu-icon-primary-color: var(--fu-color-subscription);
  }

  &--light-membership, &--dark-membership {
    --fu-color-primary: var(--fu-color-membership);
    --fu-color-primary-rgb: var(--fu-color-membership-rgb);
    --fu-button-primary-bg: var(--fu-color-membership);
    --fu-button-primary-bg-rgb: var(--fu-color-membership-rgb);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
    --fu-icon-primary-color: var(--fu-color-membership);
  }

  &--light-guide {
    --fu-color-primary: var(--fu-color-guide);
    --fu-color-primary-rgb: var(--fu-color-guide-rgb);
    --fu-button-primary-bg: var(--fu-color-guide);
    --fu-button-primary-bg-rgb: var(--fu-color-guide-rgb);
    --fu-button-primary-color: var(--fu-pure-white);
    --fu-button-primary-color-rgb: var(--fu-pure-white-rgb);
    --fu-icon-primary-color: var(--fu-color-guide);
  }

  &--dark-zpp {
    --fu-color-primary: var(--fu-color-zpp);
    --fu-color-primary-rgb: var(--fu-color-zpp-rgb);
    --fu-button-primary-bg: var(--fu-color-zpp);
    --fu-button-primary-bg-rgb: var(--fu-color-zpp-rgb);
    --fu-button-primary-color: var(--fu-black);
    --fu-button-primary-color-rgb: var(--fu-black-rgb);
    --fu-icon-primary-color: var(--fu-color-zpp);
  }
}


