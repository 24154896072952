@import '~swiper/swiper-bundle.css';

.fu-swiper {
  --swiper-theme-color: var(--fu-color-primary);
  --swiper-pagination-bullet-inactive-color: var(--fu-pure-white);

  &__slide {
    --slide-width: 300px;
    min-width: var(--slide-width);
    width: var(--slide-width);
  }

  &--pagination {
    --swiper-pagination-bottom: 0 !important;
    --swiper-theme-color: var(--fu-body-color);
    --swiper-pagination-bullet-width: 6px;
    --swiper-pagination-bullet-height: 6px;
    --swiper-pagination-bullet-horizontal-gap: calc(var(--fu-spacing-xs) / 2);
    --swiper-pagination-bullet-inactive-color: var(--fu-grey-3);
    --swiper-pagination-bullet-inactive-opacity: 1;

    &__slide-content {
      padding-bottom: var(--fu-spacing-xxl);
    }
  }
}

