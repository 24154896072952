.fu-loading-card-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.fu-loading {
  &--card {
    height: 370px;
    border-radius: var(--fu-radius-s);
  }

  &--item {
    height: 100px;
    border-radius: var(--fu-radius-s);
  }

  &--title {
    height: 54px;
    border-radius: var(--fu-radius-s);
  }
}

.fu-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;

  ion-spinner {
    transform: scale(3);
    color: var(--fu-color-primary);
  }
}
