.fu-accordion {
  ion-item {
    --background-hover: none;
    --ripple-color: transparent;
    --background-activated: transparent;
    --inner-padding-end: 0;
    --border-color: var(--fu-grey-15);

    &::part(native) {
      padding: 0;
    }

    ion-label {
      --color: var(--fu-mild-grey);
    }

    .ion-accordion-toggle-icon {
      color: var(--fu-grey-3);
      font-size: 20px;
    }
  }
}
