.fu-popover {
  --width: 250px;
  --border-radius: var(--fu-radius-xs);

  &::part(arrow) {
    display: none;
  }

  &::part(content) {
    border-radius: var(--fu-radius-xs);
  }

  &--small {
    --width: 200px;
  }
}

.fu-popover-no-backdrop {
  --backdrop-opacity: 0;

  &::part(backdrop) {
    background-color: transparent;
  }
}

@media (min-width: 1440px) {
  .fu-popover {
    --offset-x: calc(-1 * (((100vw - 1440px) / 2) + (250px / 2)));
    --offset-y: 20px;
  }
}
