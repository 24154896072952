/**
* Usage: <ion-item class="fu-input"><ion-input .../></ion-item>
 */
.fu-input {
  --background: var(--fu-input-bg);
  --color: var(--fu-input-color);
  --placeholder-color: var(--fu-input-placeholder-color);
  --placeholder-opacity: 1;
  --border-radius: var(--fu-radius-input);
  --border-style: var(--fu-input-border-style);
  --border-width: var(--fu-input-border-width);
  --border-color: var(--fu-input-border-color);

  &--entry {
    --border-radius: var(--fu-radius-s);
  }

  ion-input {
    --padding-bottom: 20px;
    --padding-top: 20px;
    --padding-start: 16px !important; // ionic resets padding-start to 0 in md - why? quick fix here...
    --padding-end: 16px;
    --border-radius: 0;
  }

  height: 60px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  &--line {
    --background: var(--fu-body-bg);
    --border-color: var(--fu-mild-grey);

    ion-input {
      --padding-start: 0;
    }
  }

  &--small {
    height: 50px;
  }

  &--medium {
    height: 56px;
    padding-left: 4px !important;
  }
}

.fu-textarea {
  --border-color: var(--fu-grey-16);
  --boder-width: 1px;
  --border-style: solid;
  --border-radius: var(--fu-radius-xs);

  border: 1px solid var(--fu-grey-16);
  border-radius: var(--fu-radius-xs);
  width: 100%;
  padding: var(--fu-radius-xs);
  min-height: 256px;
  resize: none;
  outline: none;

  &:focus, &:focus-visible {
    border: 1px solid var(--fu-grey-3);
  }

  &::placeholder {
    color: var(--fu-grey-3);
  }
}

.fu-checkbox {
  --size: 24px;
  --checkbox-background-checked: rgba(255, 255, 255, 0.3);
  --checkbox-background: transparent;
  --border-color: var(--fu-body-color);
  --border-radius: 6px;
  --border-color-checked: var(--fu-body-color);
  --checkmark-color: var(--fu-body-color);
  --checkmark-width: 6px;

  &::part(container) {
    padding: 4px;
  }

  &--primary {
    --checkbox-background-checked: var(--fu-color-primary);
    --border-color-checked: var(--fu-color-primary);
    --checkmark-color: var(--fu-body-bg);
  }
}

.fu-radio {
  &::part(container) {
    width: var(--fu-icon-font-size-regular);
    height: var(--fu-icon-font-size-regular);
    border-radius: 50%;
    border: 2px solid var(--fu-grey-16);
  }

  &.radio-checked::part(container) {
    background-color: var(--fu-green-2);
    border-color: transparent;
  }

  &.radio-checked::part(mark) {
    width: 5px;
    height: 10px;

    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: var(--fu-pure-white);

    transform: rotate(45deg);
  }
}

.fu-toggle {
  color: var(--fu-grey-5);
  font-size: 16px;
  line-height: 20px;
  padding-top: 0;
  padding-bottom: 0;
  --handle-height: 25px;
  --handle-width: 25px;
  --handle-max-height: 25px;
  --handle-max-width: 25px;
  --track-background: var(--fu-toggle-track-bg);
  --track-background-checked: var(--fu-green-2);
  --handle-background-checked: var(--fu-pure-white);
  --handle-spacing: 1px;
  width: 100%;

  &::part(track) {
    width: 50px;
    height: 30px;
  }

  span {
    white-space: normal;
  }

  &--transparent {
    --track-background-checked: rgba(var(--fu-pure-white-rgb), 0.3);

    &::part(track) {
      border: 2px solid rgba(var(--fu-pure-white-rgb), 0.3);
    }
  }
}

.fu-text-input-container, .fu-input, .fu-custom-input-container {
  .label-text-wrapper {
    display: none;
  }

  .input-highlight {
    display: none;
  }
}

.fu-text-input-container {
  width: 100%;

  .native-wrapper {
    font-size: 24px;
    line-height: 24px;
  }

  ion-input {
    --padding-top: 20px;
    --padding-end: 0;
    --padding-bottom: 20px;
    --padding-start: 0;
    --placeholder-color: var(--fu-grey-7);
    --placeholder-opacity: 1;
    border-bottom: 2px solid var(--fu-grey-1);
  }

  &--code {
    .input-wrapper {
      height: 37px;
    }

    .native-wrapper {
      font-weight: 800;
      font-size: 30px;
      line-height: 37px;
      height: 37px;
    }

    ion-input {
      --placeholder-color: var(--fu-grey-1);
      border: none;
    }

    padding-bottom: 10px;
    border-bottom: 2px solid var(--fu-grey-1);
  }
}

.fu-custom-input-container {
  .native-wrapper {
    font-size: 60px;
  }
}

div.fu-input {
  display: flex;
  align-items: center;
  background: var(--fu-input-bg);
  color: var(--fu-input-color);
  border-radius: var(--fu-radius-input);
  border-style: var(--fu-input-border-style);
  border-width: var(--fu-input-border-width);
  border-color: var(--fu-input-border-color);
  padding-left: 20px;
  overflow: hidden;

  .native-wrapper {
    font-size: 16px;
  }

  ion-icon {
    font-size: 24px;
  }

  ion-input {
    text-align: start;
    font-size: inherit;
    --placeholder-color: initial;
    --placeholder-font-style: initial;
    --placeholder-font-weight: initial;
    --placeholder-opacity: .5;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --background: transparent;
    --color: initial;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    flex: 1;
    align-items: center;
    width: 100%;
    padding: 0 !important;
    background: var(--background);
    color: var(--color);
    font-family: var(--ion-font-family, inherit);
    z-index: 2;
  }

  &--line {
    background: var(--fu-body-bg);
    border-color: var(--fu-mild-grey);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding-left: 0;

    ion-input {
      --padding-start: 0 !important;
    }
  }
}

.fu-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fu-input-error {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: var(--fu-color-error);
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 30px;
}

.fu-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;

  &__input-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--fu-spacing-xs);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

.fu-searchbar {
  --border-radius: 25px;
  --icon-color: var(--fu-grey-5);
  --clear-button-color: var(--fu-grey-5);
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;

  .searchbar-input-container {
    height: 50px;
  }

  .searchbar-input {
    font-size: 16px;
    font-weight: 600;
    padding-inline-start: 50px !important;
  }

  .searchbar-search-icon {
    top: 16px;
    margin-left: 16px !important;
    height: 18px;
    width: 18px;
    font-size: 18px;
  }

  .searchbar-clear-button {
    right: 10px;
  }
}

.fu-select {
  --padding-top: 13px;
  --padding-bottom: 13px;
  --padding-start: 30px;
  --highlight-color-focused: transparent;
  --ripple-color: transparent;
  --highlight-color-valid: transparent;
  --highlight-color: transparent;

  &::part(icon) {
    margin-left: 55px;
  }

  &--s {
    --padding-start: 16px;
  }

  &--filter {
    --padding-top: 14px;
    --padding-bottom: 14px;
    --padding-start: 32px;
    --padding-end: 32px;
  }

  &--full-width {
    &::part(container) {
      width: 100%;
    }

    &::part(icon) {
      margin-right: 30px;
    }
  }
}

.fu-input-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: var(--fu-spacing-xs);

  img {
    width: 24px;
    height: 24px;
  }
}

.fu-radio-box {
  --border-color: var(--fu-radio-box-border-color);
  --border-width: 1px;
  --border-style: solid;
  --border-radius: var(--fu-radius-3xl);
  --ripple-color: transparent;
  --background-hover: transparent;
  --inner-border-width: 0;
  --padding-start: 24px;
  --padding-end: 24px;
  --padding-top: 14px;
  --padding-bottom: 14px;
  --inner-padding-end: 0;
  --min-height: 0;

  &--selected {
    --background: var(--fu-radio-box-selected-bg);
    --border-color: var(--fu-green-2);
    --color: var(--fu-radio-box-selected-color);
  }
}
