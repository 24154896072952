.fu-button {
  --button-top-bottom-padding: calc(2 * var(--text-height));
  --button-side-padding: var(--text-height);
  --text-height: var(--fu-button-default-font-size);
  --box-shadow: none;
  --border-radius: var(--fu-radius-box-style);
  --border-color: var(--fu-button-border-color);
  --border-width: 1px;
  --padding-start: var(--button-top-bottom-padding);
  --padding-end: var(--button-top-bottom-padding);
  --padding-top: var(--button-side-padding);
  --padding-bottom: var(--button-side-padding);
  font-style: normal;
  font-weight: 600;
  font-size: var(--text-height);
  text-align: center;
  text-transform: none;
  letter-spacing: 0;
  display: block;
  margin: auto;
  width: fit-content;

  ion-icon {
    font-size: var(--fu-icon-font-size-button);
    margin-right: var(--fu-spacing-xs);
  }

  &--with-right-icon {
    ion-icon {
      margin-right: 0;
      margin-left: var(--fu-spacing-xs);
    }
  }

  &.button-round {
    --border-radius: var(--fu-radius-xl);
  }

  &.ion-color-secondary {
    --border-width: 1px;
    --border-color: var(--fu-darker-border-color);
    --border-style: solid;
  }

  &--legacy {
    width: 100%;
    margin: 0;
  }

  &--s {
    --button-side-padding: calc(var(--text-height) - 2px);
  }

  &--l, &--legacy, &--full-width {
    --button-side-padding: calc(var(--text-height) + 2px);
  }

  &--container {
    --border-color: var(--fu-body-color);
  }

  &--light, &--auth {
    --border-style: solid;
    --border-radius: var(--fu-radius-box-style);
  }

  &--outline {
    --color: var(--fu-body-color);
    --border-color: var(--fu-body-color);

    &--light {
      --border-color: var(--fu-grey-16);
    }
  }

  &--light {
    --border-color: var(--fu-grey-5);
    --ion-color-primary-contrast: var(--fu-grey-5);
    --ion-color-secondary-contrast: var(--fu-grey-5);
  }

  &--extra-light {
    --ion-color-primary-contrast: var(--fu-body-color);
    --ion-color-secondary-contrast: var(--fu-body-color);

    &.ion-color-secondary {
      --border-color: var(--fu-grey-15);
      --ion-color-base: var(--fu-grey-15) !important;
    }
  }

  &--mild-grey {
    --ion-color-primary-contrast: var(--fu-body-color);
    --ion-color-secondary-contrast: var(--fu-body-color);

    &.ion-color-secondary {
      --border-color: var(--fu-mild-grey);
      --ion-color-base: var(--fu-mild-grey) !important;
    }
  }

  &--green {
    --ion-color-primary-contrast: var(--fu-body-bg);
    --ion-color-secondary-contrast: var(--fu-body-bg);

    &.ion-color-secondary {
      --border-color: var(--fu-green-2);
      --ion-color-base: var(--fu-green-2) !important;
    }
  }

  &--light-red {
    --ion-color-primary-contrast: var(--fu-light-red);
    --ion-color-secondary-contrast: var(--fu-light-red);

    &.ion-color-secondary {
      --border-color: var(--fu-light-red) !important;
      --ion-color-shade: transparent !important;
    }
  }

  &--light-red-filled {
    --ion-color-primary-contrast: var(--fu-body-bg);
    --ion-color-secondary-contrast: var(--fu-body-bg);

    &.ion-color-secondary {
      --border-color: var(--fu-light-red);
      --ion-color-base: var(--fu-light-red) !important;
      --ion-color-shade: transparent !important;
    }
  }

  &--auth {
    --border-color: var(--fu-grey-1);
    font-size: 14px;
    font-weight: 600;

    &__icon {
      margin-right: 12.5px;
      font-size: 18px !important;
    }
  }

  &--dark {
    --border-color: var(--fu-black);
    --ion-color-secondary-contrast: var(--fu-black);
  }

  &--full-width {
    width: 100%;
  }

  &.button-disabled {
    opacity: 1;
  }

  &.button-disabled:not(.button-outline) {
    // unfortunately we have to use !important here:
    // https://stackoverflow.com/questions/54035404/ionic-change-font-color-of-disabled-button
    --ion-color-base: rgba(var(--fu-button-primary-bg-rgb, 255, 0, 0), 0.4) !important;
    --ion-color-contrast: rgba(var(--fu-button-primary-color-rgb, 255, 0, 0), 0.8) !important;
  }

  &--disabled {
    --background: var(--fu-button-disabled-bg);
    color: var(--fu-button-disabled-color);
  }
}

.fu-delete-button {
  --background: var(--fu-color-delete);
  --background-hover: var(--fu-red);
  --background-activated: var(--fu-red);
  --background-focused: var(--fu-red);
  --border-radius: 10px;
  --padding-start: 0;
  --padding-end: 0;
  height: 36px;
  width: 36px;

  ion-icon {
    font-size: 18px;
  }
}

.fu-icon-button {
  --background: var(--fu-button-ic-only-bg);
  --box-shadow: none;
  --border-radius: 6px;
  --padding-start: 0;
  --padding-end: 0;

  font-size: 7px;
  height: 32px;
  width: 32px;
  margin: 0;

  ion-icon {
    font-size: 16px;
    color: var(--fu-button-ic-only-color);
  }

  &--round {
    font-size: var(--fu-button-default-font-size);
    --padding-start: var(--fu-button-default-font-size);
    --padding-end: var(--fu-button-default-font-size);
    --padding-top: var(--fu-button-default-font-size);
    --padding-bottom: var(--fu-button-default-font-size);
    --border-radius: 50%;
  }
}

.fu-button-bottom-container {
  width: 100%;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  padding: var(--fu-spacing-xs) var(--fu-spacing-s) calc(var(--fu-spacing-s) + var(--ion-safe-area-bottom));
  background: rgba(var(--fu-body-bg-rgb), 0.9);
  backdrop-filter: blur(2px);
}

.fu-button-row-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--fu-spacing-xs);

  .fu-button {
    margin: 0;
  }
}

.fu-button-column-container {
  display: flex;
  flex-direction: column;
  gap: var(--fu-spacing-xs);
  width: 100%;
}

@media screen and (min-width: 767px) {
  .fu-setup-container {
    .fu-button-relative-container {
      position: relative;
      width: 100%;
      height: calc(100% - var(--fu-header-height));
    }

    .fu-button-bottom-container {
      position: absolute;
    }
  }
}

@media (min-width: 1024px) {
  .fu-button--legacy {
    width: auto !important; // I had to use !important
  }

  .fu-button--full-width {
    width: fit-content;
    margin: auto;
  }

  .fu-setup-container .fu-button--full-width {
    width: 100%;
    margin: 0;
  }

  .fu-button-column-container {
    width: 30%;
    min-width: 300px;
    margin: auto;

    .fu-button {
      width: 100%;
    }
  }
}
