.fu-badge {
  border-radius: var(--fu-radius-xs);
  padding: var(--fu-spacing-xs) var(--fu-spacing-s);

  &--round {
    border-radius: var(--fu-radius-xl);
  }

  &--red {
    --background: var(--fu-light-red);
  }

  &--l {
    padding: var(--fu-spacing-xs) var(--fu-spacing-l);
  }
}

.fu-badge-name {
  text-align: center;
  font-size: var(--fu-font-size-3xs);
  font-weight: var(--fu-font-semi-bold);
  color: var(--fu-grey-5);
}

.fu-coming-soon-badge {
  --background: rgba(var(--fu-button-primary-bg-rgb), 0.16);
  --color: var(--fu-color-primary);
  border-radius: 6px;
  font-size: 10px;
  line-height: 12px;
  padding: 6px 10px;
}

.fu-badge-transparent {
  --color: var(--fu-pure-white);
  --background: rgba(var(--fu-pure-white-rgb), 0.3);
  backdrop-filter: blur(2px);
  font-size: 16px;
  border-radius: var(--fu-radius-m);
  padding: var(--fu-spacing-xs) var(--fu-spacing-s);

  &--s {
    padding: var(--fu-spacing-mxs) var(--fu-spacing-s);
    font-size: 13px;
    font-weight: var(--fu-font-medium);
  }
}

.fu-badge-premium {
  display: flex;
  align-items: center;
  gap: var(--fu-spacing-xxs);
  padding: var(--fu-spacing-mxs) var(--fu-spacing-ms);
  --color: var(--fu-orange-light);
  --background: rgba(var(--fu-orange-light-rgb), 0.16);
  border-radius: var(--fu-radius-xl);
  border: 1px solid var(--fu-orange-light);
  font-size: var(--fu-font-size-5xs);
  font-weight: var(--fu-font-semi-bold);

  ion-icon {
    color: var(--fu-orange-light);
    font-size: var(--fu-font-size-m);
  }
}
