/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* New fit-up theme */

@import 'variables';
@import 'badge';
@import 'buttons';
@import 'themes';
@import 'fonts';
@import 'input';
@import 'list';
@import 'base';
@import 'card';
@import 'modal';
@import 'icons';
@import 'tables';
@import 'toast';
@import 'swiper';
@import 'segment';
@import 'helper';
@import 'loading';
@import "point";
@import "datetime";
@import "header";
@import "accordion";
@import "popover";
@import "tab-bar";
@import "challenge-list";

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-ExtraLight.woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-ExtraLightItalic.woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-Light.woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-LightItalic.woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-Regular.woff") format('njkbn');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-Italic.woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-Medium.woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-MediumItalic.woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-SemiBold.woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-SemiBoldItalic.woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-Bold.woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-BoldItalic.woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-ExtraBold.woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaSans-ExtraBoldItalic.woff");
  font-weight: 800;
  font-style: italic;
}


@font-face {
  font-family: "F-Ct-SemiBold";
  src: url("../assets/fonts/F-Ct-SemiBold.woff");
  font-weight: 600;
}

@font-face {
  font-family: "F-Ct-Bold";
  src: url("../assets/fonts/F-Ct-Bold.woff");
  font-weight: 800;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("../assets/fonts/BarlowSemiCondensed-Bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xHT3A.woff) format('woff');
}

@font-face {
  font-family: 'Gobold';
  font-weight: 400;
  src: url("../assets/fonts/GoboldRegular.woff2");
}

* {
  font-family: Plus Jakarta Sans, sans-serif !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 800;
}

body {
  margin: 0;
  padding: 0;

  background-color: var(--fu-pure-white);

  --ion-backdrop-opacity: 0.6;

  .handle-safe-area {
    margin-bottom: var(--ion-safe-area-bottom);
  }

  scroll-behavior: smooth;
  overflow-x: hidden;

  // TODO: remove when mediacenter is refactored
  app-swiper {
    .video-slide {
      width: 260px !important;
      min-width: 260px !important;
    }
  }

  #map {
    iframe {
      background-color: white !important;
    }
  }

  @media screen and (min-width: 576px) {
    .modal-wrapper {
      max-width: 450px !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  body {
    background-color: var(--fu-grey-14);
  }
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  box-shadow: inset 0 0 4px -0.5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

a {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

ul {
  padding: 0;
}

ul {
  list-style: none;
}

input {
  -webkit-appearance: none;
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: vertical;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    box-shadow: inset 0 0 4px -0.5px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }
}

button {
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
