@use 'libs/core/src/lib/theme/helper' as helper;

.fu-tab-bar {
  padding-top: var(--fu-spacing-xxs);
  padding-bottom: calc(var(--ion-safe-area-bottom) + 4px);
  background-color: var(--fu-body-bg);
  --background: var(--fu-body-bg);

  ion-tab-button {
    @include helper.flexbox();
    flex-direction: column;

    ion-icon {
      color: var(--fu-body-color);
      font-size: 32px;
      margin: 0 0 var(--fu-spacing-xxs) 0;
    }

    ion-label {
      font-size: 10px;
      color: var(--fu-mild-grey);
      margin: 0;
    }
  }
}

@media (min-width: 1024px) {
  .fu-tab-bar {
    display: flex;
    justify-content: space-around;

    ion-tab-button {
      flex-direction: row;
      gap: var(--fu-spacing-m);

      ion-icon {
        margin: 0 var(--fu-spacing-xxs) 0 0;
      }

      ion-label {
        font-size: 15px;
      }
    }
  }
}
