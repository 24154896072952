.fu-content {
  --padding-top: var(--fu-content-padding-top);
  --padding-start: var(--fu-content-padding-side);
  --padding-end: var(--fu-content-padding-side);
  --padding-bottom: calc(var(--ion-safe-area-bottom) + var(--fu-content-padding-bottom));
  padding: var(--fu-content-padding-top) var(--fu-content-padding-side) calc(var(--ion-safe-area-bottom) + var(--fu-content-padding-bottom));
  color: var(--fu-body-color);

  &--with-button {
    --padding-bottom: var(--fu-content-spacing-bottom-with-button);
    padding-bottom: var(--padding-bottom);
  }

  hr {
    width: 100%;
    margin: 0;
  }
}

.fu-content-grey {
  background: var(--fu-grey-14);
  --background: var(--fu-grey-14);
}

.fu-content-body-bg {
  background: var(--fu-body-bg);
  --background: var(--fu-body-bg);
}

.fu-content-transparent {
  background: transparent;
  --background: transparent;
}

.fu-side-padding {
  padding-right: var(--fu-content-padding-side);
  padding-left: var(--fu-content-padding-side);
}

.fu-no-side-spacing {
  margin-right: calc(-1 * var(--fu-content-padding-side));
  margin-left: calc(-1 * var(--fu-content-padding-side));
}

.fu-scrollable-content {
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fu-pre-wrap {
  white-space: pre-wrap;
}

.fu-pre-line {
  white-space: pre-line;
}

.fu-word-break-all {
  word-break: break-all;
}

.fu-text-nowrap {
  text-wrap: nowrap;
}

.fu-text-wrap {
  text-wrap: wrap;
}

.fu-disabled-text {
  color: var(--fu-color-disabled) !important;
}

.fu-progress-bar {
  height: 5px;
  --background: var(--fu-progress-bar-bg);

  &--card {
    width: 85%;
    margin: auto;
  }

  &--s {
    --progress-background: var(--fu-color-primary);
    height: 6px;
    border-radius: var(--fu-radius-3xs);
  }
}

.fu-grey-box {
  background: var(--fu-table-bg);
  padding: var(--fu-spacing-xl) var(--fu-spacing-s);
  border-radius: var(--fu-radius-s);

  &--dashed {
    border: 2px dashed var(--fu-pure-white);

    background: linear-gradient(var(--fu-table-bg) 0 0) padding-box,
    linear-gradient(to bottom, var(--fu-red), var(--fu-grey-16), var(--fu-grey-16)) border-box;
    padding: var(--fu-spacing-3xl) var(--fu-spacing-m);
  }
}

.fu-primary-box {
  border-radius: var(--fu-radius-xs);
  background: rgba(var(--fu-color-primary-rgb), 0.16);
  backdrop-filter: blur(5px);
  color: var(--fu-color-primary);
  padding: var(--fu-spacing-xs) var(--fu-spacing-s);
}

.fu-option-separator {
  display: flex;
  align-items: center;
  gap: var(--fu-spacing-m);
  color: var(--fu-grey-5);
  font-size: var(--fu-font-size-xs);
}

.fu-column-box {
  display: grid;

  &--1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .fu-box-column {
    display: flex;
    flex-direction: column;
    gap: var(--fu-spacing-xxs);
    text-align: center;
    padding: 0 var(--fu-spacing-m);
  }

  .fu-box-column + .fu-box-column {
    border-left: 1px solid var(--fu-grey-1);
  }

  .fu-box-column-label {
    color: var(--fu-grey-5);
    text-transform: uppercase;
    font-size: 10px;
  }

  .fu-box-column-value {
    font-weight: 600;
    font-size: 24px;
  }
}

a {
  color: var(--fu-body-color);
}

hr {
  width: calc(100% - 32px);
  border-bottom: 1px solid var(--fu-border-color);
  height: 0;
  margin: 0 16px;
  padding: 0;
}

.fu-thumbnail {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.fu-image-animation {
  position: absolute;
  opacity: 0.7;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

.fu-setup-container-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fu-setup-container-content {
  height: calc(100% - var(--fu-header-height));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fu-setup-container-scrollable-content {
  height: 100%;
  overflow-y: scroll;
}

.fu-admin-container {
  margin: var(--fu-spacing-s);
  background-color: var(--fu-pure-white);
  padding: var(--fu-spacing-xl);
  border-radius: var(--fu-radius-s);
}

.fu-admin-element-container {
  display: flex;
  flex-direction: column;
  gap: var(--fu-spacing-s);
  border-radius: var(--fu-radius-input);
  border: 1px solid var(--fu-input-border-color);
  padding: var(--fu-spacing-m);
}

.fu-primary-light-bg {
  background-color: rgba(var(--fu-color-primary-rgb), 0.1);
}
