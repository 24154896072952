:root {
  /* colors */
  --fu-icy-green: #dde130;
  --fu-black: #181b23;
  --fu-pure-white: #ffffff;
  --fu-silver-stone: #dcdcdc;
  --fu-mild-grey: #4a4d59;
  --fu-blue-moon: #45cbd5;
  --fu-grey-1: #e5e5e5;
  --fu-grey-2: #e6e9f2;
  --fu-grey-3: #afb4c6;
  --fu-grey-4: #aeaeae;
  --fu-grey-5: #7c8193;
  --fu-grey-6: #3b3d49;
  --fu-grey-7: #f5f5f5;
  --fu-grey-8: #dce0f0;
  --fu-grey-9: #2c2d37;
  --fu-grey-10: #3d3d3d;
  --fu-grey-11: #78788029;
  --fu-grey-12: #d9d9d9;
  --fu-grey-13: #252730;
  --fu-grey-14: #f5f7f9;
  --fu-grey-15: #E8ECF0;
  --fu-grey-16: #d1dae3;
  --fu-grey-17: #272831;
  --fu-grey-18: #292D32;
  --fu-green: #6ee032;
  --fu-green-2: #3ddc84;
  --fu-green-accept: #45d56d;
  --fu-green-light: #c9f4d5;
  --fu-green-extra-light: #E8FADE;
  --fu-red: #e13030;
  --fu-red-2: #ed1c24;
  --fu-orange: #fb942e;
  --fu-orange-medium-light: #FBBA00;
  --fu-orange-light: #ffc700;
  --fu-orange-extra-light: #ffdfbf;
  --fu-orange-mild: #ffecda;
  --fu-turquoise: #00b999;
  --fu-light-red: #ff4a4a;
  --fu-ble-blue: #0082fc;
  --fu-heart-red: #ff5050;
  --fu-light-purple: #BD6EFC;
  --fu-purple: #9747FF;
  --fu-blue: #2276f0;

  --fu-pure-white-rgb: 255, 255, 255;
  --fu-grey-5-rgb: 124, 129, 147;
  --fu-grey-6-rgb: 59, 61, 73;
  --fu-grey-15-rgb: 232, 236, 240;
  --fu-black-rgb: 24, 27, 35;
  --fu-blue-moon-rgb: 69, 203, 213;
  --fu-orange-rgb: 251, 148, 46;
  --fu-orange-light-rgb: 255, 199, 0;
  --fu-heart-red-rgb: 255, 80, 80;
  --fu-turquoise-rgb: 0, 185, 153;
  --fu-icy-green-rgb: 221, 225, 48;
  --fu-light-red-rgb: 255, 74, 74;
  --fu-light-purple-rgb: 189, 110, 252;
  --fu-blue-rgb: 34, 118, 240;
  --fu-purple-rgb: 151, 71, 255;
  --fu-green-2-rgb: 61, 220, 132;

  --fu-color-primary: var(--fu-icy-green);
  --fu-color-secondary: var(--fu-black);

  --fu-color-success: var(--fu-green-2);
  --fu-color-error: var(--fu-light-red);
  --fu-color-warning: var(--fu-orange);
  --fu-color-delete: var(--fu-light-red);
  --fu-color-disabled: var(--fu-grey-5);

  --fu-color-premium-corporate: var(--fu-orange-light);
  --fu-color-premium-private: var(--fu-blue);

  /* body */
  --fu-body-bg-light: var(--fu-pure-white);
  --fu-body-bg-rgb-light: var(--fu-pure-white-rgb);
  --fu-body-color-light: var(--fu-black);
  --fu-body-color-rgb-light: var(--fu-black-rgb);
  --fu-hr-color-light: var(--fu-grey-15);
  --fu-darker-border-light: var(--fu-grey-16);

  --fu-body-bg-dark: var(--fu-black);
  --fu-body-bg-rgb-dark: var(--fu-black-rgb);
  --fu-body-color-dark: var(--fu-pure-white);
  --fu-body-color-rgb-dark: var(--fu-pure-white-rgb);
  --fu-hr-color-dark: var(--fu-mild-grey);
  --fu-darker-border-dark: var(--fu-grey-9);

  --fu-body-bg: var(--fu-body-bg-light);
  --fu-body-bg-rgb: var(--fu-body-bg-rgb-light);
  --fu-body-color: var(--fu-body-color-light);
  --fu-body-color-rgb: var(--fu-body-color-rgb-light);
  --fu-border-color: var(--fu-hr-color-light);
  --fu-darker-border-color: var(--fu-darker-border-light);

  /* buttons */
  // TODO add rgb variables for buttons which need a disabled state
  --fu-button-primary-bg-light: var(--fu-icy-green);
  --fu-button-primary-color-light: var(--fu-black);
  --fu-button-secondary-bg-light: var(--fu-pure-white);
  --fu-button-secondary-color-light: var(--fu-black);
  --fu-button-tertiary-bg-light: var(--fu-orange-light);
  --fu-button-tertiary-color-light: var(--fu-pure-white);

  --fu-button-primary-bg-light-settings: var(--fu-black);
  --fu-button-primary-bg-light-settings-rgb: var(--fu-black-rgb);
  --fu-button-primary-color-light-settings: var(--fu-pure-white);
  --fu-button-primary-color-light-settings-rgb: var(--fu-pure-white-rgb);
  --fu-button-secondary-bg-light-settings: var(--fu-pure-white);
  --fu-button-secondary-color-light-settings: var(--fu-black);

  --fu-button-primary-bg-dark-settings: var(--fu-pure-white);
  --fu-button-primary-bg-dark-settings-rgb: var(--fu-pure-white-rgb);
  --fu-button-primary-color-dark-settings: var(--fu-black);
  --fu-button-primary-color-dark-settings-rgb: var(--fu-black-rgb);
  --fu-button-secondary-bg-dark-settings: var(--fu-black);
  --fu-button-secondary-color-dark-settings: var(--fu-pure-white);

  --fu-button-primary-bg-dark: var(--fu-icy-green);
  --fu-button-primary-color-dark: var(--fu-black);
  --fu-button-secondary-bg-dark: var(--fu-black);
  --fu-button-secondary-color-dark: var(--fu-pure-white);
  --fu-button-tertiary-bg-dark: var(--fu-orange-light);
  --fu-button-tertiary-color-dark: var(--fu-pure-white);

  --fu-button-primary-bg: var(--fu-button-primary-bg-light);
  --fu-button-primary-bg-rgb: var(--fu-button-primary-bg-light-rgb);
  --fu-button-primary-color: var(--fu-button-primary-color-light);
  --fu-button-primary-color-rgb: var(--fu-button-primary-color-light-rgb);
  --fu-button-secondary-bg: var(--fu-button-secondary-bg-light);
  --fu-button-secondary-color: var(--fu-button-secondary-color-light);
  --fu-button-tertiary-bg: var(--fu-button-tertiary-bg-light);
  --fu-button-tertiary-color: var(--fu-button-tertiary-color-light);

  --fu-button-ic-only-bg-light: var(--fu-grey-7);
  --fu-button-ic-only-color-light: var(--fu-mild-grey);

  --fu-button-ic-only-bg-dark: var(--fu-grey-9);
  --fu-button-ic-only-color-dark: var(--fu-pure-white);

  --fu-button-ic-only-bg: var(--fu-button-ic-only-bg-light);
  --fu-button-ic-only-color: var(--fu-button-ic-only-color-light);

  --fu-button-default-font-size: 14px;

  --fu-button-border-color-light: var(--fu-grey-16);
  --fu-button-border-color-dark: var(--fu-mild-grey);

  /* segment */
  --fu-segment-bg-light: var(--fu-grey-7);
  --fu-segment-bg-active-light: var(--fu-pure-white);

  --fu-segment-bg-dark: var(--fu-grey-9);
  --fu-segment-bg-active-dark: var(--fu-mild-grey);

  /* table */
  --fu-table-header-bg-light: var(--fu-grey-7);
  --fu-table-color-light: var(--fu-grey-5);
  --fu-table-divider-color-light: var(--fu-grey-1);

  --fu-table-bg-dark: var(--fu-grey-9);
  --fu-table-color-dark: var(--fu-grey-3);
  --fu-table-divider-color-dark: var(--fu-grey-10);

  --fu-table-bg: var(--fu-table-header-bg-light);
  --fu-table-color: var(--fu-table-color-light);
  --fu-table-divider-color: var(--fu-table-divider-color-light);

  /* input colors */
  --fu-input-bg-light: var(--fu-pure-white);
  --fu-input-color-light: var(--fu-black);
  --fu-input-placeholder-color-light: var(--fu-grey-5);
  --fu-input-border-style-light: solid;
  --fu-input-border-width-light: 1px;
  --fu-input-border-color-light: var(--fu-grey-15);

  --fu-input-bg-dark: var(--fu-grey-6);
  --fu-input-color-dark: var(--fu-pure-white);
  --fu-input-placeholder-color-dark: var(--fu-grey-5);
  --fu-input-border-style-dark: solid;
  --fu-input-border-width-dark: 1px;
  --fu-input-border-color-dark: var(--fu-grey-5);

  --fu-input-bg: var(--fu-input-bg-light);
  --fu-input-color: var(--fu-input-color-light);
  --fu-input-placeholder-color: var(--fu-input-placeholder-color-light);
  --fu-input-border-style: var(--fu-input-border-style-light);
  --fu-input-border-width: var(--fu-input-border-width-light);
  --fu-input-border-color: var(--fu-input-border-color-light);

  /* icon variables */
  --fu-icon-primary-color: var(--fu-color-primary);

  /* font weight variables */
  --fu-font-light: 200;
  --fu-font-regular: 400;
  --fu-font-medium: 500;
  --fu-font-semi-bold: 600;
  --fu-font-bold: 700;
  --fu-font-extra-bold: 800;

  /* font sizes */
  --fu-font-size-5xs: 10px;
  --fu-font-size-4xs: 11px;
  --fu-font-size-3xs: 12px;
  --fu-font-size-xxs: 13px;
  --fu-font-size-xs: 14px;
  --fu-font-size-s: 15px;
  --fu-font-size-m: 16px;
  --fu-font-size-l: 17px;
  --fu-font-size-xl: 18px;
  --fu-font-size-xxl: 20px;
  --fu-font-size-3xl: 24px;
  --fu-font-size-4xl: 26px;
  --fu-font-size-5xl: 28px;

  /* icon font sizes */
  --fu-icon-font-size-button: 14px;
  --fu-icon-font-size-regular: 24px;
  --fu-icon-font-size-s: 16px;
  --fu-icon-font-size-m: 18px;
  --fu-icon-font-size-mx: 20px;
  --fu-icon-font-size-l: 32px;
  --fu-icon-font-size-xml: 40px;
  --fu-icon-font-size-xl: 46px;
  --fu-icon-font-size-xxl: 60px;
  --fu-icon-font-size-3xl: 64px;
  --fu-icon-font-size-4xl: 82px;
  --fu-premium-icon-size: 22px;
  --fu-input-icon-size: 128px;

  --fu-header-bg: var(--fu-header-bg-light);
  --fu-button-border-color: var(--fu-button-border-color-light);

  /* banner */
  --fu-banner-bg-light: var(--fu-grey-7);
  --fu-banner-color-light: var(--fu-mild-grey);

  --fu-banner-bg-dark: var(--fu-grey-9);
  --fu-banner-color-dark: var(--fu-grey-3);

  --fu-banner-bg: var(--fu-banner-bg-light);
  --fu-banner-color: var(--fu-banner-color-light);

  /* CTA button */
  --fu-cta-button-bg-light: var(--fu-grey-7);
  --fu-cta-button-bg-dark: var(--fu-mild-grey);
  --fu-cta-button-bg: var(--fu-cta-bg-light);

  /* Fit-up section colors */
  --fu-color-activity: var(--fu-orange-light);
  --fu-color-activity-rgb: var(--fu-orange-light-rgb);
  --fu-color-nutrition: var(--fu-turquoise);
  --fu-color-nutrition-rgb: var(--fu-turquoise-rgb);
  --fu-color-challenge: var(--fu-orange);
  --fu-color-challenge-rgb: var(--fu-orange-rgb);
  --fu-color-subscription: var(--fu-orange-light);
  --fu-color-subscription-rgb: var(--fu-orange-light-rgb);
  --fu-color-membership: var(--fu-blue);
  --fu-color-membership-rgb: var(--fu-blue-rgb);
  --fu-color-fit-score: var(--fu-blue);
  --fu-color-fit-score-rgb: var(--fu-blue-rgb);
  --fu-color-guide: var(--fu-purple);
  --fu-color-guide-rgb: var(--fu-purple-rgb);
  --fu-color-zpp: var(--fu-green-2);
  --fu-color-zpp-rgb : var(--fu-green-2-rgb);

  /* spacings */
  --fu-spacing-3xs: 3px;
  --fu-spacing-xxs: 5px;
  --fu-spacing-mxs: 8px;
  --fu-spacing-xs: 10px;
  --fu-spacing-2ms: 12px;
  --fu-spacing-ms: 13px;
  --fu-spacing-s: 16px;
  --fu-spacing-sm: 18px;
  --fu-spacing-m: 20px;
  --fu-spacing-l: 24px;
  --fu-spacing-xl: 30px;
  --fu-spacing-xxl: 40px;
  --fu-spacing-3xl: 50px;
  --fu-spacing-4xl: 60px;
  --fu-spacing-5xl: 80px;
  --fu-spacing-6xl: 100px;

  /* border-radius */
  --fu-radius-3xs: 3px;
  --fu-radius-xxs: 4px;
  --fu-radius-mxs: 8px;
  --fu-radius-xs: 10px;
  --fu-radius-s: 16px;
  --fu-radius-m: 20px;
  --fu-radius-l: 24px;
  --fu-radius-xl: 30px;
  --fu-radius-xxl: 50px;
  --fu-radius-3xl: 60px;
  --fu-radius-box-style: 13px;
  --fu-radius-input: var(--fu-radius-box-style);

  /* content padding */
  --fu-content-padding-top: var(--fu-spacing-xl);
  --fu-content-padding-bottom: var(--fu-spacing-xl);
  --fu-content-padding-side: var(--fu-spacing-m);
  --fu-button-height: calc(3 * var(--fu-button-default-font-size));
  --fu-content-spacing-bottom-with-button: calc(var(--ion-safe-area-bottom) + var(--fu-content-padding-bottom) * 2 + var(--fu-button-height));

  /* footer info border */
  --fu-footer-info-border-light: var(--fu-grey-1);
  --fu-footer-info-border-dark: var(--fu-grey-9);

  /* progress bar */
  --fu-progress-bar-bg-light: var(--fu-grey-15);
  --fu-progress-bar-bg-dark: var(--fu-grey-9);

  /* segment bar */
  --fu-segment-bar-height: 48px;

  /* header */
  --fu-header-height: 73px;

  /* number input*/
  --fu-number-button-color-light: var(--fu-black);
  --fu-number-button-bg-light: var(--fu-grey-7);

  --fu-number-button-color-dark: var(--fu-pure-white);
  --fu-number-button-bg-dark: var(--fu-grey-9);

  --fu-number-button-color: var(--fu-number-button-color-light);
  --fu-number-button-bg: var(--fu-number-button-bg-light);

  /* modal backdrop*/
  --fu-modal-backdrop-opactiy: 0.6;

  /* header background */
  --fu-header-bg-light: var(--fu-grey-14);
  --fu-header-bg-dark: var(--fu-grey-9);

  /* editor component disabled button*/
  --fu-editor-disabled-button-bg-light: var(--fu-grey-2);
  --fu-editor-disabled-button-bg-dark: var(--fu-mild-grey);

  /* toggle */
  --fu-toggle-track-bg-light: var(--fu-grey-11);
  --fu-toggle-track-bg-dark: var(--fu-grey-5);

  /* radio box */
  --fu-radio-box-border-color-light: var(--fu-grey-15);
  --fu-radio-box-border-color-dark: var(--fu-mild-grey);
  --fu-radio-box-selected-bg-light: rgba(var(--fu-green-2-rgb), 0.16);
  --fu-radio-box-selected-bg-dark: var(--fu-green-2);
  --fu-radio-box-selected-color-light: var(--fu-body-color);
  --fu-radio-box-selected-color-dark: var(--fu-body-bg-dark);

  /* wheel datetime */
  --fu-wheel-color-light: var(--fu-mild-grey);
  --fu-wheel-color-dark: var(--fu-grey-5);
  --fu-wheel-highlight-bg-light: var(--fu-grey-14);
  --fu-wheel-highlight-bg-dark: var(--fu-grey-9);

  /* number input */
  --fu-square-input-border-color-light: var(--fu-grey-15);
  --fu-square-input-border-color-dark: var(--fu-mild-grey);
  --fu-square-input-bg-light: var(--fu-body-bg);
  --fu-square-input-bg-dark: var(--fu-grey-17);
  --fu-square-control-bg-light: var(--fu-grey-15);
  --fu-square-control-bg-dark: var(--fu-mild-grey);

  /* fu-circle-icon */
  --fu-circle-bg-color-light: var(--fu-grey-7);
  --fu-circle-bg-color-dark: var(--fu-grey-9);

  /* challenge card */
  --fu-challenge-card-bg-light: var(--fu-body-bg);
  --fu-challenge-card-bg-dark: var(--fu-grey-9);
  --fu-challenge-card-border-light: var(--fu-grey-15);
  --fu-challenge-card-border-dark: var(--fu-mild-grey);
}
