.fu-datetime {
  --ion-color-step-150: transparent;
  color: var(--fu-grey-5);
  --ion-color-base: var(--fu-body-color) !important;
  --ion-color-contrast: white !important;
  --ion-color-base-rgb: white !important;
  text-align: center;
}

.fu-wheel-datetime {
  --background: var(--fu-body-bg);
  --background-rgb: none;
  --wheel-highlight-background: var(--fu-wheel-highlight-bg);
  color: var(--fu-wheel-color);

  &::part(wheel-item active) {
    color: var(--fu-body-color);
  }
}
