.fu-list-element {
  display: flex !important;
  align-items: center;
  margin: 24px 0;

  &::before {
    content: '';
    display: inline-block;
    background: var(--fu-black);
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-right: 10px;
  }
}

.fu-list {
  width: 100%;

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --background: var(--fu-body-bg);
    --color: var(--fu-body-color);
    --border-color: rgba(29, 30, 37, 0.16); // FIXME

    .input-wrapper {
      align-items: flex-start;
    }
  }

  ion-icon {
    font-size: 12px;
    margin-right: var(--fu-spacing-s);
    margin-top: 14px;
    align-self: start;
  }

  ion-label {
    white-space: normal !important;
  }
}
