.fu-toast {
  --color: var(--fu-pure-white);

  &--success {
    --background: var(--fu-color-success);
  }

  &--error {
    --background: var(--fu-light-red);
  }
}

.fu-action-toast {
  top: var(--fu-header-height);
  --background: var(--fu-body-bg);
  --color: var(--fu-body-color);
  --border-radius: var(--fu-radius-s);
  --box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.10);

  &::part(button) {
    color: var(--fu-body-color);
    font-weight: var(--fu-font-bold);
    letter-spacing: normal;
    font-size: 14px;
  }
}
