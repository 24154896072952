.fu-challenge-list-item {
  display: grid;
  grid-template-columns: 2fr 10fr 6fr;
  align-items: center;
  padding: var(--fu-spacing-s) 0;
  border-bottom: 1px solid var(--fu-table-divider-color);
  font-size: var(--fu-font-size-xs);
  color: var(--fu-table-color);

  &--active {
    --border-width: 4px;
    background-color: rgba(var(--fu-color-primary-rgb), 0.1);
    border-left: var(--border-width) solid var(--fu-color-primary);
    color: var(--fu-body-color);
    margin-left: calc(-1 * (var(--fu-spacing-l) + var(--border-width)));
    margin-right: calc(-1 * (var(--fu-spacing-l) + var(--border-width)));
    padding-left: var(--fu-spacing-m);
    padding-right: calc(var(--fu-spacing-m) + var(--border-width));
  }

  &:last-child {
    border-bottom: none;
  }
}

