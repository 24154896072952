.fu-asterisk {
  color: var(--fu-color-primary);
}

.fu-color-primary {
  color: var(--fu-color-primary);
  border-color: var(--fu-color-primary);
}

.fu-relative {
  position: relative;
}

.fu-absolute {
  position: absolute;
}

.fu-no-border {
  border: 0;
}

.fu-border {
  border: 1px solid var(--fu-border-color);
}

.fu-border-bottom {
  border-bottom: 1px solid var(--fu-border-color);
}

.fu-border-right {
  border-right: 1px solid var(--fu-border-color);
}

.fu-gap {
  display: flex;
  flex-direction: column;

  &--s {
    gap: var(--fu-spacing-s);
  }

  &--xxs {
    gap: var(--fu-spacing-xxs);
  }

  &--mxs {
    gap: var(--fu-spacing-mxs);
  }

  &--xs {
    gap: var(--fu-spacing-xs);
  }

  &--s {
    gap: var(--fu-spacing-s);
  }

  &--sm {
    gap: var(--fu-spacing-sm);
  }

  &--ms {
    gap: var(--fu-spacing-ms);
  }

  &--m {
    gap: var(--fu-spacing-m);
  }

  &--l {
    gap: var(--fu-spacing-l);
  }

  &--xl {
    gap: var(--fu-spacing-xl);
  }

  &--xxl {
    gap: var(--fu-spacing-xxl);
  }

  &--4xl {
    gap: var(--fu-spacing-4xl);
  }

  &--6xl {
    gap: var(--fu-spacing-6xl);
  }
}

.fu-color-success {
  color: var(--fu-color-success);
}

.fu-no-margin {
  margin: 0;
}

.fu-no-margin-bottom {
  margin-bottom: 0;
}

.fu-fit-content-width {
  width: fit-content;
}

.fu-full-width {
  width: 100%;
}

.fu-fit-width {
  width: fit-content;
}

.fu-auto-width {
  width: auto;
}

.fu-full-height {
  height: 100%;
}

.fu-margin-s {
  margin: var(--fu-spacing-s);
}

.fu-margin-top-xs {
  margin-top: var(--fu-spacing-xs);
}

.fu-margin-top-s {
  margin-top: var(--fu-spacing-s);
}

.fu-margin-top-m {
  margin-top: var(--fu-spacing-m);
}

.fu-margin-top-l {
  margin-top: var(--fu-spacing-l);
}

.fu-margin-top-xl {
  margin-top: var(--fu-spacing-xl);
}

.fu-margin-top-xxl {
  margin-top: var(--fu-spacing-xxl);
}

.fu-margin-bottom-xs {
  margin-bottom: var(--fu-spacing-xs);
}

.fu-margin-bottom-xxs {
  margin-bottom: var(--fu-spacing-xxs);
}

.fu-margin-bottom-s {
  margin-bottom: var(--fu-spacing-s);
}

.fu-margin-bottom-m {
  margin-bottom: var(--fu-spacing-m);
}

.fu-margin-bottom-l {
  margin-bottom: var(--fu-spacing-l);
}

.fu-margin-bottom-xl {
  margin-bottom: var(--fu-spacing-xl);
}

.fu-margin-bottom-xxl {
  margin-bottom: var(--fu-spacing-xxl);
}

.fu-margin-bottom-3xl {
  margin-bottom: var(--fu-spacing-3xl);
}

.fu-margin-left-xxs {
  margin-left: var(--fu-spacing-xxs);
}

.fu-margin-left-s {
  margin-left: var(--fu-spacing-s);
}

.fu-margin-right-xxs {
  margin-right: var(--fu-spacing-xxs);
}

.fu-margin-left-m {
  margin-left: var(--fu-spacing-m);
}

.fu-margin-right-xs {
  margin-right: var(--fu-spacing-xs);
}

.fu-no-padding {
  padding: 0;
}

.fu-padding-top-xs {
  padding-top: var(--fu-spacing-xs);
}

.fu-padding-top-s {
  padding-top: var(--fu-spacing-s);
}

.fu-padding-s {
  padding: var(--fu-spacing-s);
}

.fu-padding-top-m {
  padding-top: var(--fu-spacing-m);
}

.fu-padding-bottom-xs {
  padding-bottom: var(--fu-spacing-xs);
}

.fu-padding-bottom-m {
  padding-bottom: var(--fu-spacing-m);
}

.fu-padding-bottom-xl {
  padding-bottom: var(--fu-spacing-xl);
}

.fu-padding-bottom-xxl {
  padding-bottom: var(--fu-spacing-xxl);
}

.fu-padding-top-l {
  padding-top: var(--fu-spacing-l);
}

.fu-padding-top-xl {
  padding-top: var(--fu-spacing-xl);
}

.fu-padding-top-xxl {
  padding-top: var(--fu-spacing-xxl);
}

.fu-padding-right-m {
  padding-right: var(--fu-spacing-m);
}

.fu-padding-left-m {
  padding-left: var(--fu-spacing-m);
}

.fu-none {
  display: none
}

.fu-flex {
  display: flex;
}

.fu-flex-1 {
  display: flex;
  flex: 1;
}

.fu-space-between {
  display: flex;
  justify-content: space-between;
}

.fu-align-center {
  display: flex;
  align-items: center;
}

.fu-align-end {
  display: flex;
  align-items: flex-end;
}

.fu-align-start {
  display: flex;
  align-items: flex-start;
}

.fu-justify-center {
  display: flex;
  justify-content: center;
}

.fu-justify-between {
  display: flex;
  justify-content: space-between;
}

.fu-justify-space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.fu-justify-end {
  display: flex;
  justify-content: flex-end;
}

.fu-justify-start {
  display: flex;
  justify-content: flex-start;
}

.fu-flex-column {
  display: flex;
  flex-direction: column;
}

.fu-wrap {
  display: flex;
  flex-wrap: wrap;
}

.fu-cursor-pointer {
  cursor: pointer;
}

.fu-cursor-default {
  cursor: default;
}

.fu-text-underline {
  text-decoration: underline;
}

.fu-text-capitalize {
  text-transform: capitalize;
}

.fu-text-capital::first-letter {
  text-transform: uppercase;
}

.fu-text-uppercase {
  text-transform: uppercase;
}

.fu-text-center {
  text-align: center;
}

.fu-text-right {
  text-align: right;
}

.fu-text-left {
  text-align: left;
}

.fu-align-self-center {
  align-self: center;
}

.fu-text-semi-bold {
  font-weight: var(--fu-font-semi-bold);
}

.fu-family-gobold {
  font-family: "Gobold", monospace !important;
}

.fu-family-barlow {
  font-family: "Barlow Condensed", serif !important;
}

.fu-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin fu-grid-columns($columns) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-align-start {
  display: flex;
  align-items: flex-start;
}

@mixin justify-content-center {
  display: flex;
  justify-content: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flexbox($align-items:center, $justify-content:center) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
}
